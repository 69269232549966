// src/components/LoadingScreen.jsx

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Updated Loading Phrases
const loadingPhrases = {
  default: [
    'Sharpening your skills...',
    'Calibrating the swing analyzer...',
    'Aligning your performance metrics...',
    'Loading personalized training modules...',
    'Optimizing your workout session...',
  ],
  missions: [
    'Deploying new training missions...',
    'Configuring your next challenge...',
    'Activating pushup battle mode...',
    'Updating leaderboard standings...',
    'Replenishing workout resources...',
  ],
  telegramConnect: [
    'Linking your Telegram account...',
    'Transmitting workout data to Telegram...',
    'Synchronizing pushup stats...',
    'Joining your Telegram fitness group...',
    'Loading your Telegram workout history...',
  ],
  guest: [
    'Setting up your guest profile...',
    'Configuring temporary access...',
    'Loading guest-specific features...',
    'Initializing guest workout environment...',
    'Preparing your guest training session...',
  ],
};

// Updated Loading States
const loadingStates = {
  default: [
    'Initializing services...',
    'Verifying your credentials...',
    'Fetching workout data...',
    'Preparing your training modules...',
    'Setting up your personalized dashboard...',
  ],
  guest: [
    'Configuring guest settings...',
    'Loading guest access...',
    'Preparing limited features...',
    'Initializing temporary session...',
    'Setting up your guest experience...',
  ],
};

const LOADING_TIMEOUT = 30000; // 30 seconds

const LoadingScreen = ({
  type = 'default',
  error = null,
  customMessage = '',
  onTimeout = () => {},
  isGuest = false,
}) => {
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [stateIndex, setStateIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [localError, setLocalError] = useState(error);

  const phrases = loadingPhrases[isGuest ? 'guest' : type] || loadingPhrases.default;
  const states = isGuest ? loadingStates.guest : loadingStates.default;

  // Cycle through loading phrases every 5 seconds
  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 5000);

    return () => clearInterval(phraseInterval);
  }, [phrases.length]);

  // Cycle through loading states every 3 seconds
  useEffect(() => {
    const stateInterval = setInterval(() => {
      setStateIndex((prevIndex) => (prevIndex + 1) % states.length);
    }, 3000);

    return () => clearInterval(stateInterval);
  }, [states.length]);

  // Increment progress deterministically
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        const increment = Math.random() * 2 + 1; // Increment between 1% and 3%
        const newProgress = prev + increment;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 300);

    return () => clearInterval(progressInterval);
  }, []);

  // Handle loading timeout
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onTimeout();
    }, LOADING_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [onTimeout]);

  // Handle external error prop changes
  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const handleRetry = () => {
    setLocalError(null);
    setProgress(0);
    setPhraseIndex(0);
    setStateIndex(0);
    // Optionally, you can also call a retry function passed via props
  };

  if (localError) {
    return (
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50"
        role="alertdialog"
        aria-labelledby="error-title"
        aria-describedby="error-description"
        aria-modal="true"
      >
        <div className="bg-red-100 text-red-700 p-6 rounded-lg max-w-sm w-full text-center">
          <h2 id="error-title" className="text-2xl font-semibold mb-4">
            Oops! Something went wrong.
          </h2>
          <p id="error-description" className="mb-4">
            {localError}
          </p>
          <button
            onClick={handleRetry}
            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 px-4"
      role="status"
      aria-live="polite"
    >
      <div className="bg-gray-800 bg-opacity-90 rounded-lg p-8 max-w-md w-full text-center">
        {/* Spinner */}
        <div className="flex justify-center mb-6">
          <div
            className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"
            aria-label="Loading spinner"
          ></div>
        </div>

        {/* Progress Message */}
        <h2 className="text-2xl font-bold text-white mb-2">
          {progress >= 100 ? 'Almost there...' : 'Loading...'}
        </h2>
        <p className="text-gray-300 mb-4">{phrases[phraseIndex]}</p>

        {/* Current Loading State */}
        <p className="text-gray-400 mb-6">
          {customMessage || states[stateIndex]}
        </p>

        {/* Progress Bar */}
        <div
          className="w-full bg-gray-700 rounded-full h-4 mb-6"
          aria-label="Loading progress"
        >
          <div
            className="bg-blue-500 h-4 rounded-full transition-all duration-500"
            style={{ width: `${Math.min(progress, 100)}%` }}
            aria-valuenow={Math.min(progress, 100)}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        {/* Footer Message */}
        <p className="text-gray-400 text-sm">
          {isGuest
            ? 'Please wait while we set up your guest workout...'
            : 'Get ready to smash those reps with RepChamp...'}
        </p>
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  type: PropTypes.oneOf(['default', 'missions', 'telegramConnect', 'guest']),
  error: PropTypes.string,
  customMessage: PropTypes.string,
  onTimeout: PropTypes.func,
  isGuest: PropTypes.bool,
};

export default LoadingScreen;
