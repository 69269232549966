import axios from 'axios';

// Get the API base URL from the environment variables, defaulting to your Firebase URL if not set.
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'https://us-central1-olftrainer-e9868.cloudfunctions.net';

console.log('[corsConfig] API_BASE_URL:', API_BASE_URL);

// Create an Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Enables cross-site Access-Control requests with credentials
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to attach the auth token to each request if it exists
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add the token to the Authorization header
    }
    return config;
  },
  (error) => {
    console.error('[corsConfig] Request interceptor error:', error);
    return Promise.reject(error); // Forward the error to be handled by the caller
  }
);

// Response interceptor to handle errors, particularly 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => response, // Simply return the response if successful
  (error) => {
    console.error('[corsConfig] Response interceptor error:', error);
    if (error.response && error.response.status === 401) {
      // Optionally handle unauthorized access, e.g., by redirecting to the login page
      // window.location.href = '/login'; 
      console.warn('Unauthorized access detected (401).'); 
    }
    return Promise.reject(error); // Forward the error to the calling function
  }
);

// General API wrapper to expose HTTP methods using Axios instance
export const api = {
  get: (url, config = {}) => axiosInstance.get(url, config), // HTTP GET
  post: (url, data, config = {}) => axiosInstance.post(url, data, config), // HTTP POST
  put: (url, data, config = {}) => axiosInstance.put(url, data, config), // HTTP PUT
  delete: (url, config = {}) => axiosInstance.delete(url, config), // HTTP DELETE
};

// Utility function to handle API requests with a try-catch block for error handling
export const corsHandler = async (request) => {
  try {
    const response = await request(); // Execute the passed request function
    return response.data; // Return the response data directly
  } catch (error) {
    console.error('[corsHandler] Error:', error); // Log any errors encountered
    throw error; // Re-throw the error to the calling function
  }
};

// Set the auth token for the Axios instance and localStorage
export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token); // Save the token to localStorage
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`; // Set the Authorization header
  } else {
    localStorage.removeItem('authToken'); // Remove token from localStorage
    delete axiosInstance.defaults.headers['Authorization']; // Remove the Authorization header
  }
};

// Get the auth token from localStorage
export const getAuthToken = () => {
  return localStorage.getItem('authToken'); // Return the stored token
};

// Export the Axios instance as default for flexibility
export default api;
